@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  font-size: 62.5%;

  --clr-grey-1: #102a42;
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: #324d67;
  --clr-grey-4: #48647f;
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: rgb(188, 204, 220);
  --clr-grey-9: #dae2ec;
  --clr-grey-10: #f1f5f8;
  --grey-11: #768c97;
  --clr-white: #fff;
  --clr-black: rgb(24, 21, 22);
  --clr-yellow: rgb(254, 240, 0);
  --clr-red: rgb(237, 28, 37);
  --bg-transparent: rgba(188, 204, 220, 0.9);
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}

body {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  background-color: var(--clr-grey-10);
  color: var(--clr-grey-1);
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
hr {
  border: none;
  border-top: 1px solid var(--clr-grey-8);
}
h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-weight: 700;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2.5rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.8rem;
}
h5 {
  font-size: 1.6rem;
}
p {
  font-size: 1.2rem;
  margin-bottom: 1.25rem;
  color: var(--clr-grey-3);
  font-weight: 300;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
img {
  display: block;
  width: 100%;
  line-height: 1;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3.6rem;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2.4rem;
  }
  h4 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.8rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
img {
  width: 100%;
}
/******** utils ******/
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.main {
  flex-grow: 1;
}
/*  global classes */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}
.section-center-small {
  width: 70vw;
  margin: 0 auto;
  max-width: var(--max-width);
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
  .section-center-small {
    width: 80vw;
  }
}
.text-center {
  text-align: center;
}
.title {
  text-align: center;
}
.title .underline {
  width: 6rem;
  height: 0.25rem;
  background: var(--clr-grey-5);
  margin-left: auto;
  margin-right: auto;
}
/******** header *************/
.header {
  background-image: url(./assets/bg-header-light.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 80vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 100%;
  position: absolute;
  bottom: -8px;
  left: 0;
}

.particles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-container {
  position: relative;
}
/****** nav ******/
#nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 8rem;
  width: 100%;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--clr-grey-10);
}
.nav-center {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 9999;
}
.logo {
  width: 50%;
  cursor: pointer;
}
.logo img {
  height: 4rem;
}
.nav-links {
  display: none;
  justify-content: space-between;
  width: 100%;
}

.nav-links a {
  font-size: 1.8rem;
  position: relative;
  padding: 0.5rem;
}
.nav-links a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background-color: var(--clr-grey-1);
  top: 100%;
  left: 0;
  transform: translateY(-25px);
  opacity: 1;
  -webkit-transition: height 0.3s, opacity 0.3s, transform 0.3s;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
}
.nav-links a:hover::after {
  transform: translateY(0px);
  height: 2px;
  opacity: 1;
}
.scrolled {
  background-color: var(--clr-grey-10) !important;
  box-shadow: var(--dark-shadow);
}
.nav-button {
  color: var(--clr-grey-1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  cursor: pointer;
  padding: 2rem 0;
  transform: rotateY(180deg);
}
.burger {
  width: 3rem;
  position: relative;
  cursor: pointer;
}

.line {
  background: var(--clr-grey-1);
  width: 80%;
  height: 2px;
  position: absolute;
  transition: all 0.4s cubic-bezier(0.26, 0.1, 0.27, 1.55);
}

.top {
  top: -10px;
  width: 40%;
}

.bottom {
  top: 10px;
  width: 100%;
}

.lines.close .top {
  top: 0px;
  width: 100%;
  transform: rotate(45deg);
  background-color: var(--clr-grey-1);
}
.lines.close .middle {
  opacity: 0;
}
.lines.close .middle,
.lines.close .bottom {
  top: 0;
  width: 100%;
  transform: rotate(-45deg);
  background-color: var(--clr-grey-1);
}

@media screen and (min-width: 900px) {
  #nav {
    background-color: initial;
  }
  .nav-center {
    max-width: var(--max-width);
  }
  .nav-button {
    display: none;
  }
  .nav-links {
    display: flex;
  }
}

/****** sidebar *****/
.sidebar {
  position: fixed;
  top: 8rem;
  right: -100%;
  width: 60%;
  height: 100vh;
  background-color: var(--clr-black);
  z-index: 4;
  transition: var(--transition);
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 8rem;
  right: -100%;
  width: 100%;
  height: 100vh;
  z-index: 4;
  transition: var(--transition);
}
.sidebar-menu-items {
  color: var(--clr-grey-10);
  padding: 1rem 2rem;
  margin: 8rem auto;
  display: flex;
  flex-direction: column;
}
.sidebar-menu-items li:not(:last-child) {
  margin-bottom: 2rem;
}

.sidebar-menu-items a {
  font-size: 1.6rem;
  transition: var(--transition);
}
.sidebar-menu-items a:hover {
  padding-left: 2rem;
}
.show-sidebar {
  right: 0;
}
@media screen and (min-width: 600px) {
  .sidebar-menu-items a {
    font-size: 2rem;
    padding: 1rem 4rem;
  }
  .sidebar-menu-items a:hover {
    padding-left: 6rem;
  }
}
@media screen and (min-width: 900px) {
  .sidebar,
  .sidebar-overlay {
    display: none;
  }
}
/******* section intro ******/
.section-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-grey-9);
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  z-index: 1;
  padding-bottom: 5rem;
}
.section-intro-bg {
  background-image: url(./assets/Nosotros.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  mix-blend-mode: overlay;
  opacity: 0.4;
  white-space: normal;
}
.title-intro {
  padding-bottom: 10rem;
}
.title-intro h2 {
  line-height: 1.7;
}
@media screen and (min-width: 1200px) {
  .section-intro {
    padding: 10rem 0;
  }
}
/******* section valor ******/
.section-valor {
  background-image: url(./assets/section-valor-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 40rem 0;
  position: relative;
  margin-top: -25vh;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
}
.section-valor-box {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--clr-grey-10);
  width: 80%;
  padding: 6rem 2rem;
  transform: translate(-50%, -50%);
}
.section-valor-box p {
  font-size: 1.6rem;
  margin-top: 1rem;
  font-weight: 400;
  line-height: 1.8;
}
@media screen and (min-width: 600px) {
  .section-valor-box {
    width: 50%;
  }
  .section-valor-box p {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1200px) {
  .section-valor-box p {
    font-size: 2rem;
  }
}

/**** section principios ******/
.section-principios {
  background-color: var(--clr-grey-9);
  padding: 10rem 0;
}
.principios-center {
  display: grid;
  gap: 2.5rem;
  margin-top: 4rem;
}
.principle {
  background-color: var(--clr-grey-8);
  text-align: center;
  padding: 2.5rem 2rem;
  border-radius: var(--radius);
}
.principle h4 {
  text-transform: capitalize;
}
.principle p {
  font-size: 1.6rem;
  color: var(--clr-grey-2);
  font-weight: 400;
}
.principle span {
  width: 6rem;
  height: 6rem;
  display: grid;
  margin: 0 auto;
  place-items: center;
  margin-bottom: 1rem;
  border-radius: 50%;
  background-color: var(--clr-grey-9);
  color: var(--clr-grey-1);
}
.principle span svg {
  font-size: 3rem;
}
@media screen and (min-width: 576px) {
  .principios-center {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 900px) {
  .section-principios {
    padding: 5rem 0;
  }
  .principios-center {
    grid-template-columns: repeat(3, 1fr);
  }
}
/**** section servicios *****/
.section-services {
  background-color: var(--clr-grey-10);
  padding: 10rem 0 0;
  line-height: 1;
}
#servicios > div > div.title {
  margin-bottom: 4rem;
}
.finance-container {
  margin-top: 4rem;
}
.finance-logo,
.estate-logo {
  position: relative;
  width: 100%;
}

.finance-description,
.estate-description {
  width: 100%;
  margin: 1rem 0;
}
.estate-description li {
  padding: 0.5rem 0;
}
.finance-title h4,
.estate-title h4 {
  text-transform: capitalize;
}
.finance-title {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 80%;
  background-color: var(--clr-grey-10);
  padding: 0.5rem;
}
.estate-title {
  position: absolute;
  top: 0%;
  right: 0;
  width: 80%;
  background-color: var(--clr-grey-10);
}
.finance-info,
.estate-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.finance-info,
.estate-info {
  display: grid;
}
.finance-description,
.estate-description {
  padding: 0.2rem 4rem;
}
.service-title-detail {
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
  font-weight: 700;
  display: grid;
  grid-template-columns: 20px 1fr;
}
.service-title-detail svg {
  font-size: 1.2rem;
  align-self: center;
}
.finance-detail-box {
  font-size: 1.2rem;
  padding: 0.1rem 2rem;
}
dl {
  margin-bottom: 1rem;
}

@media screen and (min-width: 758px) {
  .finance-info,
  .estate-info {
    display: flex;
    flex-direction: row;
  }
  .finance-logo,
  .estate-logo {
    width: 50%;
  }
  .finance-description,
  .estate-description {
    width: 50%;
  }
  .finance-logo {
    order: 2;
  }
  .finance-title {
    transform: rotateZ(90deg);
    transform-origin: left top;
    left: 5%;
    width: 50%;
  }
}
@media screen and (min-width: 900px) {
  .service-title-detail {
    font-size: 1.6rem;
  }
  .estate-description li {
    padding: 2rem 1rem;
  }
  .finance-detail-box {
    font-size: 1.4rem;
  }
  .estate-description {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 1200px) {
  .service-title-detail {
    font-size: 2rem;
  }
  .finance-detail-box {
    font-size: 1.8rem;
    padding: 0.5rem 2rem;
  }
  .finance-title h4,
  .estate-title h4 {
    font-size: 4rem;
  }
}
@media screen and (min-width: 1500px) {
  .finance-title {
    left: 4%;
  }
  .service-title-detail {
    font-size: 2.6rem;
  }
  .finance-detail-box {
    font-size: 2rem;
    padding: 0.8rem 2rem;
  }
}

/***** contact ****/
.contact-wrapper {
  border-top: 1px solid var(--clr-grey-9);
}
#contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.contact-info {
  text-align: center;
  padding: 4rem 0;
  line-height: 2;
}
.contact-info h4 {
  margin: initial;
}
.contact-info h5 {
  margin-top: 0.75rem;
}
.contact-info a {
  font-size: 2rem;
  font-weight: 700;
  display: block;
}
.contact-text-title {
  padding: 2rem 0 0;
}

.contact-text {
  border-bottom: 1px solid var(--clr-grey-9);
}
.contact-text,
.contact-logo {
  width: 100%;
}
.contact-logo {
  padding: 2rem 0;
}
.contact-logo img {
  width: 80%;
  margin: 0 auto;
}
.contact-phone {
  margin-top: 2rem;
}
.contact-phone p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
}
@media screen and (min-width: 600px) {
  #contact-container {
    flex-direction: row;
    width: 90vw;
  }
  .contact-text-title {
    text-align: left;
  }
  #contact-text-underline {
    margin: 0;
  }
  .contact-text {
    border-right: 1px solid var(--clr-grey-9);
  }
  .contact-phone p {
    font-size: 2rem;
  }
  .contact-text,
  .contact-logo {
    width: 50%;
  }
  .contact-logo {
    padding: initial;
  }
  .contact-info {
    text-align: left;
  }
  .contact-phone p {
    justify-content: flex-start;
  }
}
/***** footer ******/
footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--clr-grey-9);
  padding: 4rem 1rem;
}
footer h5,
footer a {
  font-size: 2rem;
  margin-bottom: initial;
  font-weight: 400;
}
